import { Stack, Title } from "@mantine/core";
import { createFileRoute } from "@tanstack/react-router";

const Component = () => {
    return (
        <Stack>
            <Title order={2}>Create Message</Title>
            {/* todo: make msg lol */}
        </Stack>
    );
};

/** @public */
export const Route = createFileRoute("/_auth/msg/create")({
    component: Component,
});
