import { Stack, Title } from "@mantine/core";
import { createFileRoute } from "@tanstack/react-router";

const ProfileComponent = () => {
    return (
        <Stack>
            <Title order={2}>Profile</Title>
        </Stack>
    );
};

/** @public */
export const Route = createFileRoute("/_auth/profile/")({
    component: ProfileComponent,
});
