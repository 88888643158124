import { Anchor, Button, Container, Paper, Text, Title } from "@mantine/core";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { useAuth } from "react-oidc-context";
import { z } from "zod";

const fallback = "/messages" as const;

const LoginComponent = () => {
    const auth = useAuth();

    return (
        <Container size={420} my={40}>
            <Title ta="center">Welcome back!</Title>
            <Text c="dimmed" size="sm" ta="center" mt={5}>
                Do not have an account yet?{" "}
                <Anchor size="sm" component="button">
                    Create account
                </Anchor>
            </Text>
            <Paper withBorder shadow="md" p="lg" mt="lg" radius="md">
                <Button fullWidth onClick={() => void auth.signinRedirect()}>
                    Continue to Login
                </Button>
            </Paper>
        </Container>
    );
};

/** @public */
export const Route = createFileRoute("/login")({
    validateSearch: z.object({
        redirect: z.string().optional().catch(""),
    }),
    beforeLoad: async ({ context, search }) => {
        const auth = await context.auth;
        if (auth.isAuthenticated) throw redirect({ to: search.redirect || fallback });
    },
    component: LoginComponent,
});
