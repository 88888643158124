import { UnstyledButton } from "@mantine/core";
import { Link } from "@tanstack/react-router";
import { SidebarItem, SidebarItemProps } from "shared/components/global/Sidebar/SidebarItem";
import classes from "shared/components/global/Sidebar/SidebarItem.module.css";

type SidebarLinkProps = {
    to: string;
} & SidebarItemProps;

export const SidebarLink = ({ icon, label, to, onClick }: SidebarLinkProps) => {
    return (
        <Link to={to} onClick={onClick}>
            {({ isActive }) => {
                return <SidebarItem isActive={isActive} icon={icon} label={label} />;
            }}
        </Link>
    );
};

type SidebarSubLinkProps = Omit<SidebarLinkProps, "icon">;

export const SidebarSubLink = ({ label, to, onClick }: SidebarSubLinkProps) => (
    <Link to={to} onClick={onClick}>
        {({ isActive }) => {
            return (
                <UnstyledButton
                    size="sm"
                    w="80%"
                    ml="sm"
                    pl="md"
                    style={{ justifyContent: "flex-start" }}
                    onClick={onClick}
                    className={classes.item}
                    data-active={isActive || undefined}
                >
                    {label}
                </UnstyledButton>
            );
        }}
    </Link>
);
