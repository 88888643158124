import { ActionIcon, AppShell, Burger, Group, rem, Title, Tooltip, useMantineTheme } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconUserCircle } from "@tabler/icons-react";
import { createFileRoute, Link, Outlet, redirect, useRouterState } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import { NavbarFooter, Sidebar } from "shared/components/global/Sidebar/Sidebar";
import { sidebarSubLinkData } from "shared/components/global/Sidebar/sidebar.config";
import { useColorMode } from "shared/hooks/useColorMode";
import { useIsMobile } from "shared/hooks/useIsMobile";
import { BryxLogo } from "src/assets/BryxLogo";

const AuthLayout = () => {
    const logoWidth = "30px";
    const logo = import.meta.env.VITE_ORG_LOGO_SOURCE;
    const title = import.meta.env.VITE_ORG_TITLE;

    const [navIndex, setNavIndex] = useState(0);
    const hideExpandPanel = sidebarSubLinkData[navIndex].data.length === 0;
    const [opened, { toggle }] = useDisclosure();
    const { isDark } = useColorMode();
    const router = useRouterState();
    const theme = useMantineTheme();
    const isMobile = useIsMobile();

    useEffect(() => {
        // On page load, find the tab we're on
        const maybeSubLink = sidebarSubLinkData.findIndex((link) => link.data.some((v) => v.to === router.location.pathname));
        if (maybeSubLink !== -1) setNavIndex(maybeSubLink);
    }, []);

    useEffect(() => {
        if (opened && hideExpandPanel) {
            toggle();
        }
    }, [navIndex, hideExpandPanel]);

    return (
        <AppShell
            header={{ height: 60 }}
            navbar={{ width: opened ? rem(225) : rem(50), breakpoint: theme.breakpoints.sm, collapsed: { mobile: !opened } }}
            padding="md"
        >
            <AppShell.Header>
                <Group h="100%" px="md" justify="space-between">
                    {!hideExpandPanel && <Burger opened={opened} onClick={toggle} display={!isMobile ? "none" : undefined} size="sm" />}
                    <Group pl="xs">
                        <Group>
                            {Boolean(logo) ? <img src={logo} width={logoWidth} /> : <BryxLogo width={logoWidth} />}
                            <Title order={2} c="var(--mantine-primary-color-filled)">
                                {Boolean(title) ? title : "EMNS"}
                            </Title>
                        </Group>
                    </Group>
                    <Link to="/profile">
                        <Tooltip label="Profile" position="bottom" transitionProps={{ duration: 0 }} color={isDark ? "gray" : undefined}>
                            <ActionIcon variant="filled" aria-label="Profile" radius="lg" size="lg">
                                <IconUserCircle size={20} stroke={1.5} />
                            </ActionIcon>
                        </Tooltip>
                    </Link>
                </Group>
            </AppShell.Header>
            <AppShell.Navbar>
                <Sidebar opened={opened} toggle={toggle} navIndex={navIndex} setNavIndex={setNavIndex} />
            </AppShell.Navbar>
            <AppShell.Main>
                <section>
                    <Outlet />
                </section>
            </AppShell.Main>
            <AppShell.Footer display={!isMobile ? "none" : undefined}>
                <NavbarFooter setNavIndex={setNavIndex} />
            </AppShell.Footer>
        </AppShell>
    );
};

/** @public */
export const Route = createFileRoute("/_auth")({
    beforeLoad: async ({ context, location }) => {
        const auth = await context.auth;

        if (!auth.isAuthenticated) throw redirect({ to: "/login", search: { redirect: location.href } });
    },
    component: AuthLayout,
});
