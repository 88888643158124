import { Group, Menu, SimpleGrid, Stack, Title } from "@mantine/core";
import { modals } from "@mantine/modals";
import { createFileRoute, Link, useLoaderData } from "@tanstack/react-router";
import { httpPostGraphql } from "shared/api/httpClient";
import { FloatingActionButton } from "shared/components/global/FloatingActionButton/FloatingActionButton";
import { graphql } from "src/gql";
import { match, P } from "ts-pattern";

const InboxComponent = () => {
    const data = useLoaderData({ from: "/_auth/messages/inbox" });

    const openModal = () =>
        modals.open({
            title: "Select Template",
            size: "lg",
            centered: true,
            children: (
                <SimpleGrid cols={{ base: 2 }} spacing={{ base: "md" }} verticalSpacing={{ base: "md" }}>
                    TODO: Templates
                </SimpleGrid>
            ),
        });

    return (
        <Stack>
            <Group justify="space-between">
                <Title order={2}>Message Series</Title>
            </Group>
            <SimpleGrid cols={{ base: 1 }} spacing={{ base: "md" }} verticalSpacing={{ base: "md" }}>
                <>
                    {match(data.series.length)
                        .with(P.select(P.when((n) => n > 0)), () => data.series.map((it) => <div key={it.id}>{JSON.stringify(it)}</div>))
                        .otherwise(() => (
                            <Title order={4}>No Messages</Title>
                        ))}
                </>
            </SimpleGrid>
            <FloatingActionButton>
                <Link to="/msg/create">
                    <Menu.Item>New Message</Menu.Item>
                </Link>
                <Menu.Item onClick={openModal}>From Template</Menu.Item>
            </FloatingActionButton>
        </Stack>
    );
};

/** @public */
export const Route = createFileRoute("/_auth/messages/inbox")({
    gcTime: 0,
    shouldReload: false,
    component: InboxComponent,
    loader: async ({ abortController }) => {
        const data = await httpPostGraphql(
            graphql(`
                query InboxMessages {
                    inbox(limit: 10, offset: 0) {
                        id
                        body
                        publishedAt
                        scheduledFor
                        status
                        subject
                        about {
                            didAck
                        }
                        audience {
                            geofilter
                            groups {
                                id
                                name
                            }
                            topics {
                                id
                                name
                            }
                        }
                        sender {
                            id
                        }
                        severity {
                            name
                        }
                    }
                }
            `),
            {},
            {
                signal: abortController.signal,
            },
        );

        return {
            series: data?.inbox ?? [],
        };
    },
    meta: () => [
        {
            title: "Inbox",
        },
    ],
});
