import { WebStorageStateStore } from "oidc-client-ts";
import { logger } from "shared/utils/logger";
import { z } from "zod";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

type OidcStore = {
    config: {
        authority: string;
        client_id: string;
        redirect_uri: string;
        post_logout_redirect_uri: string;
        scope: string;
        userStore: WebStorageStateStore;
    };
    setRealm: (realm: string) => void;
    getAccessToken: () => string | undefined;
};

const subdomain = window.location.hostname.split(".")[0];
const realm = subdomain == "localhost" ? import.meta.env["VITE_FALLBACK_REALM"] : subdomain;

const userStoreDefault = {
    config: {
        authority: `${import.meta.env["VITE_KC_BASE_URL"]}/realms/${realm}`,
        client_id: "emns-web",
        redirect_uri: `${window.location.origin}/account/callback`,
        post_logout_redirect_uri: `${window.location.origin}/login`,
        scope: "openid profile email",
        userStore: new WebStorageStateStore({ store: window.localStorage }),
    },
};

logger.log("Using OIDC config", userStoreDefault.config);

export const useOIDCStore = create<OidcStore>()(
    devtools(
        // https://docs.pmnd.rs/zustand/integrations/persisting-store-data#options
        persist(
            immer((set, get) => {
                return {
                    ...userStoreDefault,
                    setRealm: (realm: string) => {
                        set((state) => {
                            state.config.authority = `${import.meta.env.VITE_KC_BASE_URL}/realms/${realm}`;
                        });
                    },
                    getAccessToken() {
                        const {
                            config: { authority, client_id },
                        } = get();
                        const raw = localStorage.getItem(`oidc.user:${authority}:${client_id}`);
                        if (raw == null) return undefined;

                        const parsed = z.object({ access_token: z.string() }).safeParse(JSON.parse(raw));
                        if (parsed.success) return parsed.data.access_token;
                        else return undefined;
                    },
                };
            }),
            {
                name: "emns.store.oidc",
                storage: createJSONStorage(() => localStorage),
                partialize: (store) => ({
                    config: store.config,
                }),
            },
        ),
        { enabled: import.meta.env.VITE_ENVIRONMENT !== "prod", name: "Auth" },
    ),
);
