import { IconChartPie2, IconHome, IconInbox, IconMapPin } from "@tabler/icons-react";
import { ParseRoute } from "@tanstack/react-router";
import { routeTree } from "routeTree.gen";

export const sidebarBreakpoint = "sm";

export type SidebarLinkData = {
    icon: typeof IconHome;
    label: string;
    to: ParseRoute<typeof routeTree>["fullPath"];
};

// Invariant: if you add an item to sideBarLinkData, you must add to sidebarSubLinkData
// as well. Data can be an empty array if no sub-links exist

export const sidebarLinkData: SidebarLinkData[] = [
    { icon: IconHome, label: "Home", to: "/home" },
    { icon: IconInbox, label: "Messages", to: "/messages" },
    { icon: IconMapPin, label: "Map", to: "/map" },
    { icon: IconChartPie2, label: "Chart", to: "/chart" },
];

export const sidebarSubLinkData: { header: string; data: Pick<SidebarLinkData, "label" | "to">[] }[] = [
    {
        header: "Home",
        data: [],
    },
    {
        header: "Messages",
        data: [
            { label: "Inbox", to: "/messages/inbox" },
            {
                label: "Drafts",
                to: "/messages/drafts",
            },
            {
                label: "Scheduled",
                to: "/messages/scheduled",
            },
            {
                label: "Sent",
                to: "/messages/sent",
            },
        ],
    },
    { header: "Map", data: [] },
    { header: "Chart", data: [] },
];
