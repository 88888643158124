import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";
import { useAuth } from "react-oidc-context";

/** @public */
export const Route = createFileRoute("/account/callback")({
    component: () => {
        const auth = useAuth();
        const navigate = useNavigate();

        useEffect(() => {
            if (auth.isLoading) return;
            void navigate({ to: "/" });
        }, [auth.isLoading]);
    },
});
