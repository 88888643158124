import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { ExecutionResult, print } from "graphql";
import { useOIDCStore } from "shared/stores/authStore";
import { HttpStatusCode } from "shared/utils/http";
import { logger } from "shared/utils/logger";

const http = async <T>(path: string, { headers, ...restConfig }: RequestInit): Promise<T> => {
    const requestUrl = `${import.meta.env.VITE_API_BASE_URL}${path}`;
    const request = new Request(requestUrl, {
        ...restConfig,
        headers: {
            ...headers,
            Authorization: "Bearer " + useOIDCStore.getState().getAccessToken(),
        },
    });

    const response = await fetch(request);

    if (!response.ok) {
        throw new Error(`HTTP Error! Status: ${response.status}`);
    }

    if (response.status === HttpStatusCode.NO_CONTENT) {
        return Promise.resolve({} as T);
    }

    return response.json().catch(logger.error);
};

export const httpPostGraphql = async <T, V>(
    document: TypedDocumentNode<T, V>,
    variables: V,
    config?: RequestInit,
): Promise<NonNullable<ExecutionResult<T>["data"]>> => {
    const body = {
        query: print(document),
        variables,
    };
    const init = { method: "POST", body: JSON.stringify(body), headers: { "Content-Type": "application/json" }, ...config };

    const response = await http<ExecutionResult<T>>("/graphql", init);

    if (response.errors) {
        throw new Error("[Graphql Error]: ".concat(response.errors.map((err) => err.message).join(", ")));
    }

    if (!response.data) {
        throw new Error("[Graphql Error]: No data");
    }

    return response.data;
};
