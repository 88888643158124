import "shared/components/global/Sidebar/Sidebar.module.css";

import { ActionIcon, Box, Group, rem, Stack, Title } from "@mantine/core";
import { IconChevronLeft, IconChevronRight, IconLogout } from "@tabler/icons-react";
import { useNavigate, useRouter } from "@tanstack/react-router";
import { Dispatch, SetStateAction } from "react";
import { useAuth } from "react-oidc-context";
import { useLogout } from "shared/api/authApi";
import { sidebarLinkData, sidebarSubLinkData } from "shared/components/global/Sidebar/sidebar.config";
import { SidebarItem } from "shared/components/global/Sidebar/SidebarItem";
import { SidebarLink, SidebarSubLink } from "shared/components/global/Sidebar/SidebarLink";
import { useIsMobile } from "shared/hooks/useIsMobile";

const getLinks = (setNavIndex: Dispatch<SetStateAction<number>>) =>
    sidebarLinkData.map((link, index) => <SidebarLink {...link} key={link.label} to={link.to} onClick={() => void setNavIndex(index)} />);

export const Sidebar = ({
    navIndex,
    setNavIndex,
    opened,
    toggle,
}: {
    navIndex: number;
    setNavIndex: Dispatch<SetStateAction<number>>;
    opened: boolean;
    toggle: () => void;
}) => {
    const isMobile = useIsMobile();
    const subLinks = sidebarSubLinkData[navIndex].data.map((link) => (
        <SidebarSubLink {...link} key={link.label} to={link.to} onClick={toggle} />
    ));

    return isMobile ? subLinks : <SidebarDesktop navIndex={navIndex} setNavIndex={setNavIndex} opened={opened} toggle={toggle} />;
};

export const SidebarDesktop = ({
    navIndex,
    setNavIndex,
    opened,
    toggle,
}: {
    navIndex: number;
    setNavIndex: Dispatch<SetStateAction<number>>;
    opened: boolean;
    toggle: () => void;
}) => {
    const router = useRouter();
    const navigate = useNavigate();
    const auth = useAuth();
    const hideExpandPanel = sidebarSubLinkData[navIndex].data.length === 0;

    const { logout } = useLogout({
        onSuccess: async () => {
            await auth.removeUser();
            await auth.signoutRedirect({ id_token_hint: auth.user?.id_token });
            await auth.clearStaleState();
            await router.invalidate();

            await navigate({ to: "/" });
        },
    });

    return (
        <Stack component="nav" h="100%" dir="row" style={{ flexWrap: "wrap", gap: 0 }}>
            <Stack justify="space-between" h="100%" w={rem(50)}>
                <Stack justify="center" gap="sm">
                    {!hideExpandPanel && (
                        <ActionIcon
                            variant="outline"
                            radius="xl"
                            color="var(--mantine-color-text)"
                            style={{ position: "absolute", right: rem(-12) }}
                            bg="var(--mantine-color-body)"
                            size="sm"
                            onClick={toggle}
                        >
                            {opened ? <IconChevronLeft /> : <IconChevronRight />}
                        </ActionIcon>
                    )}
                    {getLinks(setNavIndex)}
                </Stack>

                <Stack justify="center" gap="sm">
                    <SidebarItem icon={IconLogout} label="Logout" onClick={() => logout({ auth })} />
                </Stack>
            </Stack>
            <Stack
                justify="flex-start"
                h="100%"
                w={rem(175)}
                display={opened ? undefined : "none"}
                style={{ borderLeft: "1px solid var(--app-shell-border-color)" }}
            >
                <Box
                    py="sm"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderBottom: "1px solid var(--app-shell-border-color)",
                    }}
                    fw="bold"
                >
                    <Title order={5}>{sidebarSubLinkData[navIndex].header}</Title>
                </Box>
                <Stack>
                    {sidebarSubLinkData[navIndex].data.map((link) => (
                        <SidebarSubLink {...link} key={link.label} to={link.to} />
                    ))}
                </Stack>
            </Stack>
        </Stack>
    );
};

export const NavbarFooter = ({ setNavIndex }: { setNavIndex: Dispatch<SetStateAction<number>> }) => {
    return (
        <Group h={rem(50)} justify="space-around">
            {getLinks(setNavIndex)}
        </Group>
    );
};
