import { createFileRoute, redirect } from "@tanstack/react-router";

/** @public */
export const Route = createFileRoute("/")({
    beforeLoad: async ({ context }) => {
        const auth = await context.auth;

        if (auth.isAuthenticated) {
            throw redirect({
                to: "/messages",
            });
        }

        throw redirect({
            to: "/login",
        });
    },
});
