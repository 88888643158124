import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { AuthContextProps } from "react-oidc-context";
import { AppError } from "shared/components/global/AppError/AppError";
import { NotFound } from "shared/components/global/NotFound/NotFound";

interface AuthRouterContext {
    auth: Promise<AuthContextProps>;
}

/** @public */
export const Route = createRootRouteWithContext<AuthRouterContext>()({
    errorComponent: AppError,
    notFoundComponent: NotFound,
    component: () => (
        <>
            <Outlet />
            {import.meta.env.VITE_ENVIRONMENT !== "prod" && <TanStackRouterDevtools position="bottom-right" initialIsOpen={false} />}
        </>
    ),
});
